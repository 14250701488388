import React from "react";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import reducer from "Reducers";
import App from "./App";

import "react-app-polyfill/stable";
import "react-toastify/dist/ReactToastify.min.css";
import "payplux-ui-core/dist/index.css";
import "./Scss/app.scss";

reducer();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
