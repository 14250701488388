import React, { useGlobal, Suspense, Fragment, lazy } from "reactn";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Hook } from "payplux-ui-core";

import Maintenance from "Components/Maintenance";
import Fallback from "Components/Fallback";

const Dashboard = lazy(() => import("./Views/Dashboard"));
const Auth = lazy(() => import("./Views/Auth"));

const Routes = () => {
  /**
   * state
   */
  const [token] = useGlobal("token");

  /**
   * hooks
   */
  const maintenanceMode = Hook.useConfig("web_maintenance_mode", "true");

  return (
    <Fragment>
      {process.env.REACT_APP_MAINTENANCE_MODE === "false" ? (
        <Fragment>
          {typeof maintenanceMode === "undefined" ? (
            <Fallback>Loading configurations</Fallback>
          ) : (
            <Fragment>
              {maintenanceMode ? (
                <Maintenance />
              ) : (
                <BrowserRouter>
                  <Suspense fallback={<Fallback>Loading App</Fallback>}>
                    <Switch>
                      {token && (
                        <Route path="/dashboard" component={Dashboard} />
                      )}
                      <Route path="/" component={Auth} />
                    </Switch>
                  </Suspense>
                </BrowserRouter>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Maintenance />
      )}
    </Fragment>
  );
};

/**
 * namedRoutes
 *
 * Central routing system
 */

export const namedRoutes = {
  auth: {
    index: "/auth",
    login: "/auth/login",
    signup: "/auth/signup",
    passwordReset: "/auth/password-reset",
    forgottenPassword: "/auth/forgotten-password",
    verify: {
      email: "/auth/verify-email",
      phoneNumber: "/auth/verify-phone",
      emailVerify: "/auth/verify-email/:id",
    },
  },
  dashboard: {
    index: "/dashboard",
    buy: {
      index: "/dashboard/buy",
      payment: "/dashboard/buy/payment",
    },
    sell: {
      index: "/dashboard/sell",
      payment: "/dashboard/sell/payment",
      asset:"/dashboard/sell/?asset=:asset",
    },
    wallets: {
      index: "/dashboard/wallets",
      tab: "/dashboard/wallets/:tab?/:id?",
      payment: "/dashboard/wallets/:tab/payment",
    },
    bulk: {
      index: "/dashboard/bulk",
      tab: "/dashboard/bulk/:tab?/:id?",
      payment: "/dashboard/bulk/payment/buy/:id?",
      sell: {
        index: "/dashboard/bulk/sell/",
        payment: "/dashboard/bulk/payment/sell",
      },
    },
    orders: {
      index: "/dashboard/orders",
      tab: "/dashboard/orders/:tab?/:id?",
    },
    settings: {
      index: "/dashboard/settings",
      tab: "/dashboard/settings/:tab?",
    },
    redeem: {
      index: "/dashboard/redeem",
      payment: "/dashboard/redeem/payment",
      tab: "/dashboard/redeem/:tab?",
    },
  },
};

export default Routes;
