import React from "react";
import { Icon } from "payplux-ui-core";
import Image from "react-bootstrap/Image";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(["full", "logo", "auth"]),
};

const defaultProps = {
  size: 100,
  variant: "full",
};

const CompanyLogo = (props) => {
  /**
   * variables
   */
  const isDefaultLogo =
    !Boolean(process.env.REACT_APP_COMPANY_LOGO) ||
    process.env.REACT_APP_COMPANY_LOGO?.toLowerCase() === "default";
  const width = props.width || props.size;
  const variant = props.variant;

  return isDefaultLogo ? (
    <Icon.Logo {...props} />
  ) : (
    <Image
      src={
        (variant === "logo"
          ? process.env.REACT_APP_COMPANY_LOGO
          : variant === "auth"
          ? process.env.REACT_APP_AUTH_PAGES_LOGO
          : process.env.REACT_APP_COMPANY_LOGO_FULL) ||
        process.env.REACT_APP_COMPANY_LOGO_FULL
      }
      alt={`${process.env.REACT_APP_APP_NAME || "Company"} logo`}
      width={width}
      {...props}
    />
  );
};

CompanyLogo.propTypes = propTypes;
CompanyLogo.defaultProps = defaultProps;

export default CompanyLogo;
