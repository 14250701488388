import { Util } from "payplux-ui-core";

import { rootState } from "./";

export const login = (state, dispatch, payload) => {
  // set global cookie and local state
  Util.cookie().setCookie("token", payload.access_token);
  return { token: payload.access_token };
};

export const logout = async () => {
  // delete global cookie and local state
  Util.cookie().deleteCookie("token");
  return rootState;
};
