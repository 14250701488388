import React from "react";
import { Component, Hook } from "payplux-ui-core";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

const Maintenance = () => {
  /**
   * hook
   */
  const maintenance = Hook.useConfig("web_maintenance_mode");

  return (
    <Wrapper>
      <Image src="/illustrations/maintenance.svg" className="mb-8" />
      <p className="large lh-13 mb-8">
        {maintenance?.notes ||
          `The ${process.env.REACT_APP_APP_NAME} platform is currently undergoing maintenance. Kindly check back later. Thank you.`}
      </p>

      <Component.Button
        width={200}
        className="mx-auto"
        onClick={() =>
          (window.location.href = process.env.REACT_APP_WEBSITE_URL)
        }
      >
        Go Home
      </Component.Button>
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  text-align: center;
  padding: 3rem 1rem;
  max-width: 600px;
  margin: auto;

  img {
    max-width: 500px;
    margin: 0px auto;
    display: block;
  }
`;

export default Maintenance;
