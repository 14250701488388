import React, { useGlobal, useDispatch, Fragment } from "reactn";
import { ErrorBoundary } from "@sentry/react";
import { Provider } from "payplux-ui-core";
import { Helmet } from "react-helmet";

import Routes from "./Routes";
import Fallback from "Components/Fallback";

const App = () => {
  /**
   * state
   */
  const [token, setToken] = useGlobal("token");

  /**
   * dispatcher
   */
  const logout = useDispatch("auth.logout");

  /**
   * variables
   */
  const isDefaultLogo =
    !Boolean(process.env.REACT_APP_COMPANY_LOGO) ||
    process.env.REACT_APP_COMPANY_LOGO?.toLowerCase() === "default";

  return (
    <ErrorBoundary
      fallback={
        <Fallback withLoader={false}>
          An error occurred. Our devs have been deployed to work on it
        </Fallback>
      }
    >
      <Fragment>
        <Helmet>
          <title>{process.env.REACT_APP_APP_NAME}</title>
          <link
            rel="icon"
            href={
              isDefaultLogo
                ? "/favicon.ico"
                : process.env.REACT_APP_COMPANY_LOGO
            }
          />
          <link
            rel="apple-touch-icon"
            href={
              isDefaultLogo ? "/192.png" : process.env.REACT_APP_COMPANY_LOGO
            }
          />
        </Helmet>

        <Provider.App>
          <Provider.Auth {...{ token, setToken, logout }}>
            <Routes />
          </Provider.Auth>
        </Provider.App>
      </Fragment>
    </ErrorBoundary>
  );
};

export default App;
