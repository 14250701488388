import React from "react";
import BarLoader from "react-spinners/BarLoader";
import styled from "styled-components";
import PropTypes from "prop-types";

import CompanyLogo from './CompanyLogo';

const propTypes = {
  withLoader: PropTypes.bool,
};

const defaultProps = {
  withLoader: true,
};

const Fallback = ({ withLoader, children }) => (
  <Wrapper>
    <div className="text-center">
      <div className="mb-8">
        <CompanyLogo variant="logo" size={48} />
      </div>

      <h5 className="fw-bolder">{children}</h5>
      {withLoader && (
        <BarLoader
          height={5}
          width={280}
          css={{ display: "block", marginTop: 16 }}
          color="var(--clear-blue)"
        />
      )}
    </div>
  </Wrapper>
);

/**
 * styles
 */
const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`;

Fallback.propTypes = propTypes;
Fallback.defaultProps = defaultProps;

export default Fallback;
